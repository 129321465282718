<template>
    <div>
        <img
            class="filter_icon"
            src="/assets/filter_button_dark.png"
            @click="toggleFilters"
        >
    
        <perfect-scrollbar v-if="showFilters" class="filters-container">
            <div id="filters" ref="filters">
                <div class="filters__content">
                    <div class="filters__header">
                        <h1>Volgende wedstrijden</h1>
    
                        <button class="btn-icon" @click="toggleFilters">
                            <i class="fas fa-times" />
                        </button>

                        <div class="filters__badges">
                            <span
                                v-for="filter in basicRange"
                                :key="filter.value"
                                class="badge badge_btn"
                                :class="{ 'badge_active': filter.value === currentFilters.basicRange }"
                                @click="updateFilters('basicRange', filter.value)"
                                v-text="filter.name"
                            />
                        </div>
                    </div>
                    
                    <div v-if="eventsData.length > 0" class="filter_event_container">
                        <div
                            v-for="event in eventsData"
                            :key="event.id"
                            class="filter__event"
                        >
                            <div
                                class="filter__center_location"
                                @mouseover="mouseover(event)"
                                @click="$emit('goToEvent', { _id: event._id, open: true })"
                            />
                            <div class="filters__eventdate">
                                <EventDate
                                    :date="event.eventdate"
                                    :name="event.name"
                                />
                            </div>
                        </div>
                    </div>
                        
                    <div v-else>
                        <p class="mb-1" v-text="'Geen evenementen gevonden'" />
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script setup>
import { ref, defineProps, onMounted, defineEmits, nextTick } from 'vue';
import lodash from 'lodash';
import EventDate from './shared/EventDate.vue';

const emit = defineEmits(['applyFilter', 'goToEvent']);
const props = defineProps({
    events: {
        type: Array,
        required: true,
    },
});

const showFilters = ref(false);
const currentFilters = ref({
    basicRange: 'thisYear',
});
const basicRange = ref([
    { name: 'Deze maand', value: 'thisMonth' },
    { name: 'Volgende maand', value: 'nextMonth' },
    { name: 'Dit jaar', value: 'thisYear' },
]);
const eventsData = ref([]);

onMounted(() => {
    applyFilters();

    if (window.innerWidth >= 768) {
        showFilters.value = true;
    }
});

const refreshData = () => {
    if (props.events.length === 0) {
        eventsData.value = [];
        return;
    }
    
    eventsData.value = props.events.map((event) => {
        event.eventdate = new Date(event.eventdate);
        return event;
    }).sort((a, b) => a.eventdate - b.eventdate);
};

const applyFilters = () => {
    emit('applyFilter', currentFilters.value);
    nextTick(() => {
        refreshData();
    });
};

const updateFilters = (type, value) => {
    currentFilters.value[type] = value;
    applyFilters();
};

const goToEvent = (event) => {
    emit('goToEvent', { _id: event._id, open: false })
};

const mouseover = (event) => {
    lodash.debounce(goToEvent, 200)(event);
};

const toggleFilters = () => {
    showFilters.value = !showFilters.value;
};
</script>

<style scoped lang="scss">
.btn {
    margin-top: 20px;
}

.filter_event_container {
    margin-top: 10px;

    .filter__event {
        border-bottom: 1px solid #ccc;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        &:hover {
            background-color: #f5f5f5;
        }
        
        .filter__center_location {
            width: 70px;
            height: 60px;
            margin-left: 5%;
            cursor: pointer;
            background-image: url('../../public/assets/icon_center_location.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .filter__center_location:hover {
            background-image: url('../../public/assets/icon_center_location_hover.png');
            background-repeat: no-repeat;
            background-size: 100%;
        }

        .filters__eventdate {
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }
}
</style>