<template>
    <div id="support">
        <Form
            ref="form"
            action="support"
            action-label="Versturen"
            :fields="fields"
            :add-captcha="true"
            @submit="send"
        />

        <p v-if="globalError">
            <span class="error" v-text="globalError" />
        </p>

        <p v-if="globalMessage">
            <span v-text="globalMessage" />
        </p>
    </div>
</template>

<script>
import Form from '../shared/Form.vue';

export default {
    components: {
        Form,
    },
    data() {
        return {
            fields: [
                {
                    id: 'name',
                    label: 'Naam',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'email',
                    label: 'E-mail',
                    type: 'email',
                    required: true,
                },
                {
                    id: 'message',
                    label: 'Bericht',
                    type: 'textarea',
                    required: true,
                }
            ],
        }
    },
    watch: {
        loading(value) {
            this.$emit('setLoadingState', value);
            this.$refs.form.submitting = value;
        },
    },
    methods: {
        async send(body) {
            this.loading = true;

            let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/support`, {
                method: 'POST',
                headers: {
                    'x-captcha-token': body.captchaToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fields: body.fields }),
            });

            this.loading = false;
            this.$refs.form.submitting = false;

            if (response.statusCode === 200) {
                let data = await response.json();
                this.globalMessage = data.message;
            } else {
                this.globalError = 'Error al enviar el mensaje';
            }
        },
    },
}
</script>
