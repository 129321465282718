<template>
    <div id="register">
        <h1>Dossier</h1>
        
        <Form
            ref="form"
            :class="{
                'form__hidden': step > 1,
            }"
            action="register"
            action-label="Doorgaan"
            :fields="fields"
            :add-captcha="true"
            @submit="next()"
        />

        <div v-if="step == 2">
            <div class="eula">
                <h2>Licentieovereenkomst voor eindgebruikers</h2>
                <p>Laatste update: 25 maart 2024</p>
                <p>Blue Lab Software verleent deze licentie voor het gebruik van Racetrijd ("De Applicatie") aan u ("eindgebruiker"), voor gebruik uitsluitend onder de voorwaarden van deze licentie.</p>
                <h3>INHOUDSOPGAVE</h3>
                <ol>
                    <li>DE APPLICATIE</li>
                    <li>LICENTIEBEREIK</li>
                    <li>DOOR GEBRUIKERS GEGENEREERDE INHOUD</li>
                </ol>
                <h3>1. DE APPLICATIE</h3>
                <p>Racetrijd is een stukje software dat is gemaakt om alle georganiseerde hardloopwedstrijdevenementen in de buurt van de gebruikersruimte te kunnen bekijken, ten behoeve van gemeenschapsgebruik.</p>
                <h3>2. OMVANG VAN DE LICENTIE</h3>
                <p>Blue Lab Software verleent Licentienemer een niet-overdraagbare, royaltyvrije, niet-exclusieve licentie voor toegang tot en gebruik van de Applicatie volledig binnen de reikwijdte van de Gebruikersinterface.</p>
                <h3>3. DOOR GEBRUIKERS GEGENEREERDE INHOUD</h3>
                <p>Vanaf nu noemen we 'uw bijdragen' de inhoud die door de eindgebruiker is gepubliceerd, of het nu gaat om opmerkingen, afbeeldingen of welk type visuele inhoud dan ook in de applicatie.</p>
                <p>3.1 Onder geen enkele omstandigheid zullen uw bijdragen inhoud bevatten die aanstootgevend kan zijn (door ons) of die als ongepast kan worden beschouwd.</p>
                <p>3.2 Uw bijdragen zullen niet obsceen zijn en aanzetten tot geweld</p>
                <p>3.3 Uw bijdragen moeten andere gebruikers respecteren, beledigend gedrag wordt niet getolereerd.</p>
                <p>3.4 Uw bijdragen mogen niemand belachelijk maken, imiteren, intimideren of misbruiken.</p>
                <p>3.5 Uw bijdragen zullen geen specifieke persoon of welk type persoon dan ook bedreigen of lastigvallen.</p>
                <p>3.6 Uw bijdragen zullen geen toepasselijke wet- of regelgeving schenden.</p>
                <p>3.7 Uw bijdragen bevatten geen aanstootgevende opmerkingen ten aanzien van welk ras, nationaliteit, religie, seksuele geaardheid of fysieke omstandigheden dan ook.</p>
                <p>3.8 Uw bijdragen zullen geen enkele link bevatten die in strijd is met de voorwaarden van deze overeenkomst.</p>
                <h3>3. BEËINDIGING</h3>
                <p>Deze licentie is geldig totdat deze door Blue Lab Software of door u wordt beëindigd. Uw rechten onder deze licentie worden onmiddellijk door Blue Lab Software beëindigd als u zich niet aan de voorwaarden van deze licentie houdt. Bij beëindiging van deze licentie dient u alle exemplaren die geheel of gedeeltelijk in het bezit zijn van Racetrijd te vernietigen</p>
            </div>

            <p>
                <Form
                    ref="form_eula"
                    action="accepteula"
                    action-label="Registreren"
                    :fields="fieldsEula"
                    :add-captcha="true"
                    @submit="register()"
                />
            </p>

            <p>
                <button class="btn" @click="prev()">
                    Terug
                </button>
            </p>
        </div>

        <p>
            Heeft u al een gebruiker?
            <a href="javascript:void(0);" @click="showLogin">InLoggen</a>
        </p>
    </div>
</template>

<script>
import Form from '../shared/Form.vue';

export default {
    components: {
        Form,
    },
    data() {
        return {
            fields: [
                {
                    id: 'username',
                    label: 'Gebruiker',
                    value: '',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'email',
                    label: 'E-mail',
                    value: '',
                    type: 'email',
                    required: true,
                },
                {
                    id: 'password',
                    label: 'Watchword',
                    value: '',
                    type: 'password',
                    required: true,
                },
                {
                    id: 'password_repeat',
                    label: 'Herhaal wachtwoord',
                    value: '',
                    type: 'password',
                    repeat: 'password',
                    required: true,
                },
            ],
            fieldsEula: [
                {
                    id: 'eula',
                    label: 'Ik verklaar dat ik het volgende heb gelezen en aanvaard: ',
                    value: false,
                    type: 'checkbox',
                    required: true,
                    link: 'eula.html',
                    linkText: 'Licentieovereenkomst voor eindgebruikers',
                },
            ],
            loading: false,
            step: 1,
        };
    },
    watch: {
        loading(loading) {
            this.$emit('setLoadingState', loading);
            this.$refs.form.submitting = loading;
        },
    },
    mounted() {
        document.querySelector('#username').focus();
    },
    methods: {
        next() {
            this.step++;
        },
        prev() {
            this.step--;
        },
        async register() {
            let body = this.$refs.form.body;
            let form_eula = this.$refs.form_eula.body;
            
            if (form_eula.fields.find(field => field.id === 'eula' && field.value === false)) {
                this.$refs.form_eula.setError('eula', 'U moet de licentieovereenkomst voor eindgebruikers accepteren');
                return;
            }
            
            this.loading = true;
            this.$refs.form.globalError = '';
            this.$refs.form.globalMessage = '';

            let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/user/register`, {
                method: 'POST',
                body: JSON.stringify(body),
            });
            
            let data = await response.json();

            this.loading = false;

            if (response.status == 200) {
                this.$nextTick(() => {
                    this.$emit('showActivate');
                });
            } else {
                this.$refs.form.cleanPassword();
                this.$refs.form.globalError = data.error;
            }
        },
        showLogin() {
            this.$emit('showLogin');
        },
    },
};
</script>

<style scoped>
.eula {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 270px;
    overflow-y: scroll;
    text-align: justify;
}

.eula p {
    margin-top: 10px;
}

.eula input {
    margin-right: 10px;
}

.eula span {
    margin-left: 10px;
}

.form__hidden {
    display: none;
}


</style>