<template>
    <div>
        <div id="marketing">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1>Wat is Racetrijd?</h1>
                    </div>
                </div>

                <div v-if="isWeb">
                    <a href="https://play.google.com/store/apps/details?id=com.bluelabsoft.racetrijd" target="_blank">
                        <img src="/assets/disponible_play.png" width="60%">
                    </a>

                    <p><b>Próximamente en:</b></p>
                    <img src="/assets/disponible_apple.png" width="54%">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isWeb: !process.env.VUE_APP_IS_MOBILE,
        };
    },
};
</script>

<style>
#marketing {
    padding-left: 20px;
    padding-right: 20px;
}

#marketing .container {
    max-width: 960px;
    margin: 0 auto;
}

#marketing .row {
    margin-bottom: 50px;
}

#marketing .col-md-6 {
    margin-bottom: 20px;
}

#marketing h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

#marketing p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

#marketing img {
    margin-bottom: 20px;
}

#marketing a {
    margin-top: 20px;
}

#marketing a img {
    margin-right: 10px;
}
</style>