<template>
    <capacitor-google-map id="map_event" />
</template>

<script>
import { GoogleMap } from '@capacitor/google-maps';

export default {
    props: {
        event: {
            type: Object,
            default: () => ({}),
        },
        isNightime: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mapOptions: {
                mapId: this.isNightime
                    ? process.env.VUE_APP_MAPS_MAP_ID_NIGHT
                    : process.env.VUE_APP_MAPS_MAP_ID_DAY,
                scrollwheel: false,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                draggable: false,
                keyboardShortcuts: false,
            },
            commonMarkerOptions: {
                draggable: false,
                iconSize: { width: 48, height: 48, },
                iconAnchor: { x: 24, y: 48, },
            },
            map: null,
        };
    },
    async created() {
        this.$nextTick(async () => {
            await this.loadMap();
        });
    },
    methods: {
        async loadMap() {
            let element = document.getElementById('map_event');
            
            let obj = {
                id: 'racetrijd_event',
                element,
                apiKey: process.env.VUE_APP_MAPS_EVENT_API_KEY,
                config: {
                    ...this.mapOptions,
                    center: {
                        lat: this.event.latitude,
                        lng: this.event.longitude,
                    },
                    zoom: 15,
                },
            };
            
            this.map = await GoogleMap.create(obj);

            await this.map.addMarker({
                coordinate: {
                    lat: this.event.latitude,
                    lng: this.event.longitude,
                },
                title: this.event.name,
                iconUrl: 'assets/marker_event.png',
                draggable: false,
                ...this.commonMarkerOptions,
            });
        },
    },
}
</script>