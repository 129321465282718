<template>
    <div v-if="modal" class="modal">
        <div v-if="images.length > 1" class="navigation">
            <button
                :disabled="modalIndex === 0"
                class="prev"
                @click="prevImage"
            >
                <i class="fas fa-chevron-left" />
            </button>
            <button
                :disabled="modalIndex === images.length - 1"
                class="next"
                @click="nextImage"
            >
                <i class="fas fa-chevron-right" />
            </button>
        </div>
        <div class="modal-content">
            <img :src="getImage(images[modalIndex])">
        </div>
        <div class="quit-cross">
            <button @click="closeModal">
                <i class="fas fa-times" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            modal: false,
            modalIndex: 0,
        };
    },
    created() {
        document.addEventListener('keyup', (e) => {
            if (e.keyCode === 27) {
                if (this.modal) {
                    this.closeModal();
                } else {
                    this.$emit('quitEvent');
                }
            }
        });

        document.addEventListener('click', (e) => {
            if (this.modal && e.target.classList.contains('modal')) {
                this.closeModal();
            }
        });

        document.addEventListener('keydown', (e) => {
            if (this.modal) {
                if (e.keyCode === 37) {
                    if (this.modalIndex > 0) {
                        this.prevImage();
                    }
                } else if (e.keyCode === 39) {
                    if (this.modalIndex < this.images.length - 1) {
                        this.nextImage();
                    }
                }
            }
        });
    },
    methods: {
        openModal(index) {
            this.modal = true;
            this.modalIndex = index;
        },
        closeModal() {
            this.modal = false;
        },
        prevImage() {
            this.modalIndex--;
        },
        nextImage() {
            this.modalIndex++;
        },
        getImage(image) {
            return `https://images.racetrijd.nl/events/${image.event}/${image.file}`;
        },
    },
};
</script>