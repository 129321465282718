<template>
    <section v-if="!loadingApp" id="panel">
        <div class="toggle">
            <button v-if="!open" class="btn-icon" @click="toggle">
                <i class="fas fa-bars" />
            </button>
        </div>

        <div v-if="open" class="background" />

        <div v-if="loading" class="loading">
            <i class="fas fa-spinner fa-spin" />
        </div>

        <div v-if="open" class="panel-content">
            <div class="content">
                <img src="/assets/ic_launcher.png" class="panel__logo">

                <div v-if="session">
                    <Profile
                        v-if="mode == 'profile'"
                        @showLogin="showLogin('logout')"
                        @deleteAccount="mode = 'profileDelete'"
                    />

                    <ProfileDelete
                        v-if="mode == 'profileDelete'"
                        @setLoadingState="setLoadingState"
                        @showProfile="showProfile"
                        @showLogin="showLogin"
                        @reactToSession="$emit('reactToSession')"
                    />
                </div>
                <div v-else>
                    <Login
                        v-if="mode == 'login'"
                        @setLoadingState="setLoadingState"
                        @showProfile="showProfile"
                        @showRegister="mode = 'register'"
                        @showForgot="mode = 'recover'"
                    />

                    <Register
                        v-if="mode == 'register'"
                        @setLoadingState="setLoadingState"
                        @showProfile="mode = 'profile'; session = true"
                        @showLogin="mode = 'login'"
                        @showActivate="mode = 'activate'"
                    />

                    <Recover
                        v-if="mode == 'recover'"
                        @setLoadingState="setLoadingState"
                        @showLogin="mode = 'login'"
                        @showRecoverConfirm="mode = 'recoverconfirm'"
                    />

                    <RecoverConfirm
                        v-if="mode == 'recoverconfirm'"
                        @setLoadingState="setLoadingState"
                        @showLogin="mode = 'login'"
                    />

                    <Activate
                        v-if="mode == 'activate'"
                        @setLoadingState="setLoadingState"
                        @showProfile="showProfile"
                    />

                    <Support
                        v-if="mode == 'support'"
                        @setLoadingState="setLoadingState"
                        @showLogin="mode = 'login'"
                    />

                    <Marketing
                        v-if="mode == 'marketing'"
                        @setLoadingState="setLoadingState"
                        @showLogin="mode = 'login'"
                    />
                </div>
                
                <button class="btn-icon" @click="toggle">
                    <i class="fas fa-times" />
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import Login from './PanelLogin.vue';
import Register from './PanelRegister.vue';
import Profile from './PanelProfile.vue';
import ProfileDelete from './PanelProfileDelete.vue';
import Recover from './PanelRecover.vue';
import RecoverConfirm from './PanelRecoverConfirm.vue';
import Activate from './PanelActivate.vue';
import Support from './PanelSupport.vue';
import Marketing from './PanelMarketing.vue';

import Utility from '../../utility';

/**
 * All possible modes of the panel.
 * 
 * [
 *   'login',
 *   'register',
 *   'recover',
 *   'recoverconfirm',
 *   'activate',
 *   'support'
 *   'marketing',
 *   'profile',
 *   'profileDelete',
 * ]
 * 
 */


export default {
    name: 'Panel',
    components: {
        Profile,
        ProfileDelete,
        Login,
        Register,
        Recover,
        RecoverConfirm,
        Activate,
        Support,
        Marketing,
    },
    props: {
        loadingApp: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            open: false,
            session: false,
            mode: 'login',
            loading: false,
            utility: new Utility(),
            isWeb: !process.env.VUE_APP_IS_MOBILE,
        }
    },
    created() {
        if (this.utility.session()) {
            this.session = true;
        } else {
            let support = this.utility.getQueryParam('support');

            if (support) {
                this.open = true;
                this.mode = 'support';
            }


            let marketing = this.utility.getQueryParam('marketing');
            
            if (marketing) {
                this.open = true;
                this.mode = 'marketing';
            }
        }
    },
    methods: {
        toggle() {
            this.open = !this.open;

            if (this.open) {
                this.mode = this.session ? 'profile' : 'login';
            }
        },
        showLogin(action) {
            if (action == 'logout') {
                this.$emit('reactToSession');
            }

            this.open = true;
            this.mode = 'login';
            this.session = false;
        },
        setLoadingState(state) {
            this.loading = state;
        },
        showProfile() {
            this.$emit('reactToSession');
            this.open = true;
            this.mode = 'profile';
            this.session = true;
        },
    },
};
</script>

<style>
.panel__logo {
    width: 150px;
    height: 150px;
}
</style>