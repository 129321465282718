/**
 * Utility class for different components
 * 
 * @since June 2022
 * @author Federico Sosa
 */

export default class Utility {
    constructor(component) {
        this.component = component;
    }

    /**
     * Returns one event by its location
     * 
     * @param {double} lat 
     * @param {double} lng 
     * @returns {object} event
     */
    findEventByLocation(lat, lng) {
        let event = null;

        for (let sk of this.component.events) {
            if (sk.latitude == lat && sk.longitude == lng) {
                event = sk; 
                break;
            }
        }

        return event;
    }

    /**
     * Gets query parameters from url
     * 
     * @param {string} url 
     * @param {string} param 
     * @returns 
     */
    getQueryParam(param) {
        let url = window.location.href;
        
        if (url.indexOf('?') == -1) {
            return null;
        }

        let query = url.split('?')[1];
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (pair[0] == param) {
                return pair[1];
            }
        }

        return null;
    }

    /**
     * Limits the number of decimals of a number
     * 
     * @param {number} num 
     * @param {number} digits 
     * @returns 
     */
    limitDecimals(num, digits) {
        let multiplier = Math.pow(10, digits);
        let roundedNum = Math.round(num * multiplier) / multiplier;
        return roundedNum;
    }

    /**
     * Format datetime to spanish format
     * 
     * @param {string} datetime
     * @returns {string}
     */
    formatDate(date) {
        let dateObj = new Date(date);
        let dateStr = dateObj.toLocaleDateString('es-ES', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
        let timeStr = dateObj.toLocaleTimeString('es-ES', {
            hour: 'numeric',
            minute: '2-digit'
        });

        return dateStr + ' ' + timeStr;
    }

    session() {
        let exp = localStorage.getItem('exp');
        
        if (!exp || exp && exp < Date.now()) {
            localStorage.removeItem('exp');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
            localStorage.removeItem('blocks');
            return false;
        }

        return true;
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('exp');
        localStorage.removeItem('blocks');
    }

    getDistance(eventLocation, userLocation) {
        let distance = Math.sqrt(
            Math.pow(eventLocation.lat - userLocation.lat, 2) +
            Math.pow(eventLocation.lng - userLocation.lng, 2)
        );

        return distance;
    }
}
