<template>
    <section id="map_section">
        <div v-if="!loading">
            <Event
                v-if="showEvent"
                ref="event"
                :event="eventSelected"
                :is-nightime="isNightime"
                :show-event-background="showEventBackground"
                @quitEvent="quitEvent"
                @showLogin="$emit('showLogin')"
                @rating-changed="onRatingChanged"
            />

            <Filters
                :events="filteredEvents"
                @applyFilter="applyFilter"
                @goToEvent="(args) => goToEvent(args._id, args.open)"
            />

            <References />
        </div>

        <button
            id="closest"
            class="btn"
            @click="setCamera({ lat: closestEvent.latitude, lng: closestEvent.longitude })"
        >
            Naar dichtst
            <i class="fas fa-map-marker-alt" />
        </button>

        <capacitor-google-map id="map" ref="map" />
    </section>
</template>

<script>
import Event from './Event/Event.vue';
import Utility from '../utility';
import References from './References.vue';
import Filters from './Filters.vue';
import { Geolocation } from '@capacitor/geolocation';
import { GoogleMap } from '@capacitor/google-maps';
import CameraDesktop from '../classes/CameraDesktop';
import { NETHERLANDS_BOUNDS } from '../utilities/constants';

export default {
    components: {
        Event,
        References,
        Filters,
    },
    props: {
        isNightime: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            map: null,
            user: { coordinates: { lat: 0, lng: 0 } },
            screen: { coordinates: { lat: 0, lng: 0 } },
            showEvent: false,
            isBrowserLocationOn: false,
            movingToClosestInMotion: false,
            showEventBackground: false,
            isMobileDevice: () => process.env.VUE_APP_IS_MOBILE === 'true',
            mapDesktop: new CameraDesktop(this),
            filteredEvents: [],
            eventSelected: null,
            endpoint: process.env.VUE_APP_API_GATEWAY_URL,
            utility: new Utility(this),
            closestEvent: null,
            events: [],
        }
    },
    watch: {
        async filteredEvents(newValue, oldValue) {
            let markerIds = oldValue.map(event => event.marker).filter(marker => marker !== undefined);

            if (markerIds.length > 0) {
                await this.map.removeMarkers(markerIds);
            }

            let markersToAdd = [];
            for (let event of newValue) {
                markersToAdd.push({
                    coordinate: {
                        lat: event.latitude,
                        lng: event.longitude,
                    },
                    title: event.name,
                    iconUrl: 'assets/marker_event.png',
                    draggable: false,
                    iconSize: { width: 52, height: 52, },
                    iconAnchor: { x: 26, y: 52, },
                });
            }
            
            let markersIds = await this.map.addMarkers(markersToAdd);

            for (let i = 0; i < newValue.length; i++) {
                newValue[i].marker = markersIds[i];
            }
        }
    },
    mounted() {
        document.querySelector('#map').style.height = `${window.innerHeight}px`;
    },
    async created() {
        let location = await this.getGPS();
        let requests = [
            fetch(`${this.endpoint}/v1/event`),
            fetch(`${this.endpoint}/v1/images`),
        ];

        if (this.isBrowserLocationOn == false) {
            requests.push(fetch(`${this.endpoint}/v1/location`));
        }

        let responses = await Promise.all(requests);
        
        let eventsRes = await responses[0].json();
        let imagesRes = await responses[1].json();

        if (!location) {
            let locationRes = await responses[2].json();

            if (locationRes.data.status === "fail") {
                // set some default location
                location = { lat: 52.3676, lng: 4.9041 };
            } else {
                location = {
                    lat: locationRes.data.lat,
                    lng: locationRes.data.lon,
                };
            }

        }
    
        let events = eventsRes.data;
        let images = imagesRes.data;

        // match images to events
        this.events = events.map(event => {
            return {
                ...event,
                images: images.filter(image => image.event === event._id),
            };
        });

        let eventId = this.utility.getQueryParam('event');

        if (eventId) {
            this.eventSelected = this.events.find(event => event._id === eventId);

            if (this.eventSelected) {
                this.showEventBackground = true;
                this.showEvent = true;

                // update map location to event selected
                this.screen.coordinates = {
                    lat: this.eventSelected.latitude,
                    lng: this.eventSelected.longitude,
                };
            } else {
                history.pushState({}, '', '/');
            }
        } else {
            this.screen.coordinates = location;
        }
        
        this.user.coordinates = location;

        // calculate distance between user and closest event
        this.events.forEach(event => {
            let eventLocation = {
                lat: event.latitude,
                lng: event.longitude,
            };

            let userLocation = {
                lat: this.user.coordinates.lat,
                lng: this.user.coordinates.lng,
            };

            event.distance = this.utility.getDistance(eventLocation, userLocation);
        });

        // sort events by distance
        this.events.sort((a, b) => {
            return a.distance - b.distance;
        });

        this.closestEvent = this.events[0];

        this.map = await GoogleMap.create({
            id: 'racetrijd',
            element: document.getElementById('map'),
            apiKey: process.env.VUE_APP_MAPS_API_KEY,
            config: {
                zoom: 10,
                mapId: this.isNightime
                    ? process.env.VUE_APP_MAPS_MAP_ID_NIGHT
                    : process.env.VUE_APP_MAPS_MAP_ID_DAY,
                disableDefaultUI: true,
                zoomControl: true,
                center: this.user.coordinates,
                restriction: {
                    latLngBounds: NETHERLANDS_BOUNDS,
                    strictBounds: false,
                },
            },
        });

        this.filteredEvents = this.events;

        // add marker for location, use default marker
        await this.map.addMarker({
            coordinate: this.user.coordinates,
            title: 'Jouw locatie',
            iconUrl: 'assets/marker_location_2.png',
            draggable: false,
            iconSize: { width: 28, height: 28, },
            iconAnchor: { x: 14, y: 28, },
        });

        this.map.setOnCameraIdleListener((e) => {
            this.screen.coordinates.lat = e.latitude;
            this.screen.coordinates.lng = e.longitude;
        });

        this.map.setOnMarkerClickListener((event) => {
            this.goToEvent(event);
        });

        this.$emit('onReady');

        window.addEventListener('resize', this.onResize);
    },
    methods: {
        async setCamera(target) {
            if (this.isMobileDevice()) {
                await this.map.setCamera({
                    coordinate: target,
                    animate: true,
                    animationDuration: 500,
                });
            } else {
                await this.mapDesktop.setCamera(target);
            }
        },
        async getGPS() {
            let location = null;
            let coordinates = await Geolocation.getCurrentPosition()
                .catch(console.log);

            if (coordinates) {
                this.isBrowserLocationOn = true;

                location = {
                    lat: coordinates.coords.latitude,
                    lng: coordinates.coords.longitude,
                };
            }

            return location;
        },
        onResize() {
            document.querySelector('#map').style.height = `${window.innerHeight}px`;
        },
        async goToEvent(targetEvent, open = true) {
            if (typeof targetEvent === 'string') {
                targetEvent = this.events.find(event => event._id === targetEvent);
            }


            const previousEvent = this.eventSelected;
            
            if ((targetEvent._id !== previousEvent?._id && !this.movingToClosestInMotion) || open) {// && ) || (open === true && !this.movingToClosestInMotion) || (open === true && targetEvent._id === this.eventSelected?._id)) {
                this.eventSelected = this.utility.findEventByLocation(targetEvent.latitude, targetEvent.longitude);

                if (open) {
                    this.showEventBackground = true;
                }

                if (this.eventSelected._id !== previousEvent?._id) {
                    await this.setCamera({ lat: this.eventSelected?.latitude, lng: this.eventSelected?.longitude });
                }

                if (open) {
                    this.showEvent = true;
                    window.history.pushState({}, '', `?event=${this.eventSelected._id}`);
                }

            }
        },
        quitEvent() {
            this.showEvent = false;
            this.showEventBackground = false;
            this.eventSelected = null;
            window.history.pushState({}, '', '/');
        },
        applyFilter(filters) {
            console.log(filters);
            this.filteredEvents = this.events.filter(event => {
                let eventDate = new Date(event.eventdate);
                let now = new Date();

                switch (filters.basicRange) {
                case 'thisMonth':
                    return eventDate.getMonth() === now.getMonth();
                case 'nextMonth':
                    return eventDate.getMonth() === now.getMonth() + 1;
                case 'thisYear':
                    return eventDate.getFullYear() === now.getFullYear();
                default:
                    return true;
                }
            });
        },
        reactToSession() {
            if (this.showEvent) {
                this.$refs.event.reactToSession();
            }
        },
        onRatingChanged(rating) {
            this.events.find(event => {
                return event._id === this.eventSelected._id;
            }).rating = rating;
        },
    },
};
</script>

<style>
.btn#closest {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 210px;
    top: 20px;
    font-size: 1rem;
    z-index: 10;
    border: 4px solid white;
}

@media (max-width: 768px) {
    .btn#closest {
        width: 180px;
        font-size: 0.8rem;
    }
}
</style>
