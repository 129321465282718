<template>
    <div id="login">
        <h1>Inloggen</h1>
        
        <Form
            ref="form"
            action="login"
            action-label="Inloggen"
            :fields="fields"
            :add-captcha="true"
            @submit="login"
        />

        <p>
            Heb je geen gebruiker?
            <a href="javascript:void(0);" @click="showRegister">Registreer</a>
        </p>

        <p>
            <a href="javascript:void(0);" @click="showForgot">Wachtwoord herstellen</a>
        </p>
    </div>
</template>

<script>
import Form from '../shared/Form.vue';

export default {
    components: {
        Form,
    },
    data() {
        return {
            fields: [
                {
                    id: 'username',
                    label: 'Gebruiker',
                    value: '',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'password',
                    label: 'Wachtwoord',
                    value: '',
                    type: 'password',
                    required: true,
                    customValidation: {
                        minLength: 4,
                    },
                },
            ],
            loading: false,
        };
    },
    watch: {
        loading(loading) {
            this.$emit('setLoadingState', loading);
            this.$refs.form.submitting = loading;
        },
    },
    methods: {
        async login(body) {
            this.loading = true;
            this.$refs.form.globalError = '';
            this.$refs.form.globalMessage = '';

            let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/user/login`, {
                method: 'POST',
                body: JSON.stringify(body),
            });
            
            let data = await response.json();

            this.loading = false;

            if (response.status == 200) {
                localStorage.setItem('user', JSON.stringify(data.user));
                localStorage.setItem('blocks', JSON.stringify(data.blocks));
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('exp', new Date().getTime() + 3600 * 1000);

                // this next tick is needed to execute the loading watcher first
                this.$nextTick(() => {
                    this.$emit('showProfile');
                });
            } else {
                this.$refs.form.cleanPassword();
                this.$refs.form.globalError = data.error;
            }
        },
        showRegister() {
            this.$emit('showRegister');
        },
        showForgot() {
            this.$emit('showForgot');
        },
    },
};
</script>
