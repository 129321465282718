/**
 * @description This file contains all the constants used in the application.
 * 
*/

export const apiBaseUrl = `${process.env.VUE_APP_API_GATEWAY_URL}/v1/`;

export const NETHERLANDS_BOUNDS = {
    north: 54.509865,
    south: 49.7343,
    west: 1.314971,
    east: 9.2275,
};
