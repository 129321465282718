<template>
    <div class="comments">
        <div class="info">
            <h3>Opmerkingen</h3>
        </div>

        <div class="content list">
            <Comment
                ref="newComment"
                type="new"
                :event-id="eventId"
                :loading="loading"
                :creating="creating"
                :user="user"
                @getComments="getComments"
                @addComment="addComment"
                @showLogin="$emit('showLogin')"
                @setCreatingState="setCreatingState"
            />

            <p v-if="creating" class="comments_loading">
                <i class="fas fa-spinner fa-spin" />
            </p>

            <Comment
                v-for="comment in comments"
                ref="comment"
                :key="comment._id"
                :event-id="eventId"
                :comment="comment"
                :user="user"
                type="published"
                :loading="loading"
                @getComments="getComments"
                @removeComment="removeComment"
                @closeAllOptions="closeAllOptions"
                @blockUser="blockUser"
            />
            
            <p v-if="!loading && comments.length == 0" class="comments_empty">
                Er zijn geen opmerkingen.
            </p>
        </div>

        <p v-if="loading" class="comments_loading">
            Opmerkingen laden...
            <i class="fas fa-spinner fa-spin" />
        </p>
    </div>
</template>

<script>
import Comment from './Comment.vue';
import Utility from '../../../utility';

export default {
    components: {
        Comment,
    },
    props: {
        eventId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            comments: [],
            loading: true,
            creating: false,
            user: null,
            utility: new Utility(),
        };
    },
    created() {
        if (this.utility.session()) {
            this.user = JSON.parse(localStorage.getItem('user'));
        }

        this.getComments();
    },
    methods: {
        async getComments() {
            this.loading = true;
            let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/event/${this.eventId}/comment`);
            let body = await response.json();

            this.loading = false;

            if (response.status == 200) {
                this.comments = [];

                let allComments = body.data;

                if (this.utility.session()) {
                    let blocks = JSON.parse(localStorage.getItem('blocks'));

                    if (blocks) {
                        for (let block of blocks) {
                            for (let comment of allComments) {
                                if (comment.user._id == block.userBlocked) {
                                    comment.blocked = true;
                                }
                            }
                        }
                    }

                    allComments = allComments.filter(comment => !comment.blocked);
                }

                this.comments = allComments;
            } else {
                this.error = body.error;
            }
        },
        addComment(comment) {
            this.comments.unshift(comment);
        },
        reactToSession() {
            if (this.utility.session()) {
                this.user = JSON.parse(localStorage.getItem('user'));
            }

            this.getComments();
        },
        removeComment(commentId) {
            this.comments = this.comments.filter(comment => comment._id != commentId);
        },
        blockUser(userId) {
            this.comments = this.comments.filter(comment => comment.user._id != userId);
        },
        setCreatingState(state) {
            this.creating = state;
        },
        closeAllOptions(commentId) {
            for (let comment of this.$refs.comment) {
                if (comment.comment._id != commentId) {
                    comment.closeOptions();
                }
            }
        },
    },
};
</script>

<style>
.comments {
    text-align: left;
}

.comments .comments_empty {
    text-align: center;
    margin-top: 20px;
}

.comments .comments_loading {
    text-align: center;
    margin-top: 20px;
}
</style>
