<template>
    <div v-if="!loading" class="comment" :class="{'comment_deleting': deleting || flagging || banning}">
        <div v-if="type == 'published'" class="comment_published">
            <button
                v-if="session() && !flagged"
                class="btn-icon btn-icon__small comment_options"
                @click="openOptions(comment._id)"
            >
                <i class="fas fa-ellipsis-h" />
            </button>

            <div class="comment_username">
                {{ comment.user.username }}
            </div>

            <div class="comment_message">
                {{ comment.message }}
            </div>

            <div class="comment_date">
                {{ utility.formatDate(comment.createdDate, "es") }}
            </div>

            <p v-if="flagged" class="comment__flag" v-text="flagResponse" />
        
            <CommentOptions
                v-if="session() && !flagged && !banned"
                ref="options"
                :comment="comment"
                :deleting="deleting"
                :flagging="flagging"
                :user="user"
                :flagged="flagged"
                :banned="banned"
                @removeComment="removeComment"
                @deleting="setDeleting"
                @flagging="setFlagging"
                @banning="setBanning"
                @flagComment="flagComment"
                @blockUser="blockUser"
            />
        </div>

        <div v-if="type == 'new'">
            <div v-if="session()" class="comment_new">
                <div class="comment_new_button">
                    <button
                        class="btn-icon btn-icon__small"
                        @click="addComment"
                    >
                        <i class="fas fa-paper-plane" />
                    </button>
                </div>

                <div class="comment_new_input">
                    <div class="comment_username">
                        {{ user.username }}:
                    </div>
                    <input
                        ref="message"
                        v-model="message"
                        type="text"
                        placeholder="Schrijf een reactie..."
                        :disabled="loading || creating"
                        @keyup.enter="addComment"
                    >
                </div>
            </div>
            <div v-else class="comment_new comment_new__session_no">
                <p>
                    <a href="javascript:void(0)" @click="$emit('showLogin')">InLoggen</a> om te reageren.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import utility from '../../../utility';
import { apiBaseUrl } from '../../../utilities/constants';
import CommentOptions from './CommentOptions.vue';

export default {
    components: {
        CommentOptions
    },
    props: {
        eventId: {
            type: String,
            default: '',
        },
        comment: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: 'published',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        creating: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            utility: new utility(this),
            message: '',
            deleting: false,
            flagging: false,
            banning: false,
            flagged: false,
            banned: false,
            flagResponse: '',
        };
    },
    created() {
        this.$nextTick(() => {
            window.addEventListener('keydown', (e) => {
                if (e.keyCode == 13) {
                    e.preventDefault();
                }
            });
        });
    },
    methods: {
        async addComment(event) {
            if (this.message.length > 3 && this.utility.session()) {
                this.$emit('setCreatingState', true);
                let message = this.message;
                this.message = '';

                let response = await fetch(`${apiBaseUrl}/event/${this.eventId}/comment`, {
                    method: 'POST',
                    body: JSON.stringify({
                        message,
                        accessToken: localStorage.getItem('accessToken'),
                    })
                });

                let body = await response.json();
                this.$emit('setCreatingState', false);

                if (response.status == 200) {
                    this.$emit('addComment', body.data);
                } else {
                    this.error = body.error;
                }
            }
        },
        session() {
            return this.utility.session();
        },
        openOptions(commentId) {
            this.$emit('closeAllOptions', commentId);
            if (!this.flagging && !this.deleting) {
                this.$refs.options.toggle();
            }
        },
        setDeleting(val) {
            this.deleting = val;
        },
        setFlagging(val) {
            this.flagging = val;
        },
        setBanning(val) {
            this.banning = val;
        },
        flagComment(responseText) {
            this.flagged = true;
            this.flagResponse = responseText;
        },
        removeComment(commentId) {
            this.$emit('removeComment', commentId);
        },
        blockUser(userId) {
            this.$emit('blockUser', userId);
        },
        closeOptions() {
            if (this.$refs.options) {
                this.$refs.options.close();
            }
        },
    },
};
</script>

<style>
.comment:nth-child(odd),
.comment:nth-child(odd) .comment_delete,
.comment:nth-child(odd) .comment_options,
.comment:nth-child(odd) .comment_new_button .btn-icon {
    background-color: #f5f5f5;
}

.comment:nth-child(odd) .comment_new {
    padding: 10px;
}

.comment {
    padding: 10px;
    position: relative;
}
.comment.comment_deleting {
    opacity: 0.5;
}

.comment_username {
    font-weight: bold;
    font-size: 16px;
}

.comment_message {
    margin-top: 0.2rem;
}

.comment_date {
    font-size: 0.8rem;
    color: #999;
    margin-top: 0.2rem;
}

.comment .btn-icon.comment_delete {
    position: absolute;
    right: 10px;
    top: 10px;
}

.comment .btn-icon.comment_options {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.comment .btn-icon.comment_options i {
    color: #999;
}

.comment .comment_new {
    display: flex;
    flex-direction: row;
}

.comment .comment_new.comment_new__session_no {
    text-align: center;
    width: 100%;
    display: block;
}

.comment .comment_new_input {
    flex: 13;
}

.comment .comment_new_input input {
    width: calc(100% - 40px);
    height: 30px;
    border: 1px solid #ccc !important;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #333;
    padding: 5px;
    background-color: #fff;
    margin-top: 0.2rem;
}

.comment .comment_new_input input:focus {
    outline: none;
}

.comment .comment_new_button {
    flex: 1;
    text-align: left;
    margin-top: 5px;
}

.comment .comment_new_button .btn-icon {
    padding: 0;
    font-size: 22px;
}

.comment .comment__flag {
    font-size: 12px;
    color: red;
}
</style>
