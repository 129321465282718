<template>
    <div>
        <div v-if="loading" id="loading" ref="loading">
            <img src="/assets/ic_launcher.png" class="app__logo"><br>
            <i class="fas fa-spinner fa-spin" />
        </div>

        <div id="app">
            <Panel
                ref="panel"
                :loading-app="loading"
                @reactToSession="reactToSession"
            />

            <Map
                ref="map"
                :is-nightime="new Date().getHours() > 17 && new Date().getHours() < 7"
                :loading="loading"
                @onReady="init()"
                @showLogin="$refs.panel.showLogin()"
            />

            <Modal ref="modal" />
        </div>
    </div>
</template>

<script>
import Map from './components/GlobalMap';
import Panel from './components/Panel/Panel';
import Modal from './components/shared/Modal';

export default {
    name: 'App',
    components: {
        Map,
        Panel,
        Modal,
    },
    data() {
        return {
            loading: true,
        };
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    methods: {
        init() {
            this.loading = false;
        },
        onResize() {
            if (this.loading) {
                this.$refs.loading.style.height = `${window.innerHeight}px`;
                this.$refs.loading.style.width = `${window.innerWidth}px`;
            }
        },
        reactToSession() {
            this.$refs.map.reactToSession();
        },
    },
}
</script>
