<template>
    <div id="activate">
        <h1>Activering</h1>

        <p>Voer de code in die u per e-mail heeft ontvangen:</p>
        
        <Form
            ref="form"
            action="activate"
            action-label="Activeren"
            :fields="fields"
            :add-captcha="true"
            @submit="activate"
        />

        <p v-if="error">
            <span class="error" v-text="error" />
        </p>
    </div>
</template>

<script>
import Utility from '../../utility';
import Form from '../shared/Form.vue';

export default {
    components: {
        Form,
    },
    data() {
        return {
            loading: false,
            utility: new Utility(),
            error: null,
            fields: [
                {
                    id: 'activationCode',
                    label: 'Code',
                    value: '',
                    type: 'text',
                    required: true,
                    customValidation: {
                        minLength: 8,
                        maxLength: 8,
                    },
                },
            ],
        };
    },
    watch: {
        loading(loading) {
            this.$emit('setLoadingState', loading);
            this.$refs.form.submitting = loading;
        },
    },
    methods: {
        async activate(body) {
            this.loading = true;

            let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/user/activate`, {
                method: 'POST',
                body: JSON.stringify(body),
            });
            
            let data = await response.json();

            this.loading = false;
            
            if (response.status === 200) {
                localStorage.setItem('user', JSON.stringify(data.user));
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('exp', new Date().getTime() + 3600 * 1000);

                window.history.pushState({}, document.title, window.location.origin);

                // this next tick is needed to execute the loading watcher first
                this.$nextTick(() => {
                    this.$emit('showProfile');
                });
            } else {
                this.error = data.error;
            }
        },
        showLogin() {
            this.$emit('showLogin');
        },
    },
};
</script>
