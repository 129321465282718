<template>
    <form @submit.prevent="submit">
        <div
            v-for="field in fields"
            :key="field.id"
            class="form-group"
            :class="{
                'checkbox': field.type === 'checkbox' && field.link,
            }"
        >
            <label
                :for="field.id"
                v-text="field.label"
            />

            <a v-if="field.type == 'checkbox' && field.link" :href="field.link" target="_blank">{{ field.linkText }}</a>

            <input
                v-if="isInput(field)"
                :id="field.id"
                :ref="field.id"
                v-model="field.value"
                class="form-control"
                :type="field.type"
                :disabled="submitting"
                :required="field.required"
                @onblur="validate"
                @keyup="validate"
            >

            <textarea
                v-if="isTextarea(field)"
                :id="field.id"
                :ref="field.id"
                v-model="field.value"
                class="form-control"
                :disabled="submitting"
                :required="field.required"
                @onblur="validate"
                @keyup="validate"
            />

            <span
                v-if="errors[field.id]"
                class="error"
                v-text="errors[field.id]"
            />
        </div>

        <p v-if="globalError">
            <span class="error" v-text="globalError" />
        </p>

        <p v-if="globalMessage">
            <span v-text="globalMessage" />
        </p>

        <button
            ref="submit_button"
            class="btn"
            :disabled="submitting"
            type="button"
            @click="submit"
            v-text="actionLabel"
        />
    </form>
</template>

<script>

export default {
    props: {
        fields: {
            type: Array,
            default: () => [],
        },
        actionLabel: {
            type: String,
            default: 'ENVIAR',
        },
        action: {
            type: String,
            default: null,
        },
        addCaptcha: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            errors: {},
            firstValidation: false,
            submitting: false,
            defaultField: {
                id: '',
                label: '',
                value: '',
                type: '',
                required: false,
                validations: true,
            },
            globalError: '',
            globalMessage: '',
            body: {},
        };
    },
    computed: {
        valid() {
            return this.firstValidation && Object.keys(this.errors).length === 0;
        },
    },
    created() {
        this.setDefaults();

        window.addEventListener('keydown', (e) => {
            if (e.keyCode === 13 && this.valid && !this.submitting) {
                this.submit();
            }
        });

        this.$nextTick(() => {
            for (let field of this.fields) {
                let fieldElm = document.getElementById(field.id);
                
                if (fieldElm) {
                    fieldElm.addEventListener('paste', (e) => {
                        setTimeout(() => {
                            let newValue = e.path[0].value;
                            this.validate();
                        }, 0);
                    });
                }
            }
        });
    },
    methods: {
        setError(fieldId, message) {
            this.errors[fieldId] = message;
        },
        cleanPassword() {
            this.errors = {};
            this.firstValidation = false;

            for (let field of this.fields) {
                if (field.type === 'password') {
                    field.value = '';
                }
            }
        },
        cleanFormAndInputs() {
            this.errors = {};
            this.firstValidation = false;
            this.submitting = false;
            this.globalError = '';
            this.globalMessage = '';
            
            for (let field of this.fields) {
                field.value = '';
            }
        },
        async submit() {
            this.submitting = true;

            this.validate();

            if (this.valid) {
                let captchaToken;

                if (this.addCaptcha) {
                    let action = this.action;
                    captchaToken = await grecaptcha.execute(process.env.VUE_APP_CAPTCHA_SITE_KEY, { action });
                }

                let data = {
                    fields: this.fields,
                };

                if (this.addCaptcha) {
                    data.captchaToken = captchaToken;
                }

                this.body = data;
                this.$emit('submit', data);
            } else {
                this.submitting = false;
            }
        },
        validate() {
            this.errors = {};
            this.firstValidation = true;

            this.fields.forEach(field => {
                if (field.validations && field.customValidation && field.value) {
                    let validations = field.customValidation;
                    let errors = [];

                    if (validations.minLength && field.value.length < validations.minLength) {
                        this.errors[field.id] = `Het veld moet minimaal ${validations.minLength} tekens bevatten`;
                    }

                    if (validations.maxLength && field.value.length > validations.maxLength) {
                        this.errors[field.id] = `Het veld mag maximaal ${validations.maxLength} tekens bevatten`;
                    }

                    if (validations.regex && !validations.regex.test(field.value)) {
                        this.errors[field.id] = `Het veld voldoet niet aan het vereiste formaat`;
                    }
                } else {
                    if (field.validations && field.required && !field.value) {
                        this.errors[field.id] = 'Dit veld is verplicht';
                    }

                    if (field.validations && field.type === 'email' && field.value && !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(field.value)) {
                        this.errors[field.id] = 'De e-mail is niet geldig';
                    }

                    if (field.validations && field.type === 'password' && field.value) {
                        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,}$/.test(field.value)) {
                            this.errors[field.id] = 'Het wachtwoord moet minimaal 8 tekens bevatten, één hoofdletter, één kleine letter en één cijfer';
                        }

                        if (field.repeat && this.fields.find(f => f.id === field.repeat).value != '' && field.value !== this.fields.find(f => f.id === field.repeat).value) {
                            this.errors[field.id] = 'Wachtwoorden komen niet overeen';
                        }
                    }

                    if (field.validations && field.type === 'text' && field.value && field.value.length < 3) {
                        this.errors[field.id] = 'Het veld moet minimaal 3 tekens lang zijn';
                    }

                    if (field.validations && field.type === 'text' && field.value && field.value.length > 20) {
                        this.errors[field.id] = 'Het veld moet korter zijn dan 20 tekens';
                    }
                } 
            });
        },
        isInput(field) {
            return [
                'text',
                'email',
                'password',
                'number',
                'checkbox',
                'tel',
                'url',
                'date',
            ].includes(field.type);
        },
        isTextarea(field) {
            return field.type === 'textarea';
        },
        setDefaults() {
            for (let field of this.fields) {
                if (Object.keys(field).indexOf('id') == -1)
                    field.id = 'field';

                if (Object.keys(field).indexOf('label') == -1)
                    field.label = 'Campo';

                if (Object.keys(field).indexOf('value') == -1)
                    field.value = '';

                if (Object.keys(field).indexOf('type') == -1)
                    field.type = 'text';

                if (Object.keys(field).indexOf('required') == -1)
                    field.required = false;

                if (Object.keys(field).indexOf('validations') == -1)
                    field.validations = true;
            }
        },
    },
};
</script>

<style scoped>
form a {
    font-size: 14px;
    margin-left: 10px;
}

.form-group.checkbox {
    text-align: center;
}
</style>

