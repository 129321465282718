<template>
    <div v-if="open" class="options">
        <ul>
            <li v-if="session() && comment.user._id == user._id" @click="deleteComment()">
                Elimineren<button class="btn-icon btn-icon__small">
                    <i class="fas fa-trash-alt" />
                </button>
            </li>
            <li v-if="session() && comment.user._id != user._id && !flagged" @click="flagComment()">
                Rapport<button class="btn-icon btn-icon__small">
                    <i class="fas fa-flag" />
                </button>
            </li>
            <li v-if="session() && comment.user._id != user._id && !banned" @click="banUser()">
                Blokkeer gebruiker<button class="btn-icon btn-icon__small">
                    <i class="fas fa-ban" />
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import utility from '../../../utility';

export default {
    name: 'CommentOptions',
    props: {
        comment: {
            type: Object,
            required: true,
        },
        deleting: {
            type: Boolean,
            required: true,
        },
        flagging: {
            type: Boolean,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        flagged: {
            type: Boolean,
            required: true,
        },
        banned: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            open: false,
            utility: new utility(),
            init: false,
        };
    },
    methods: {
        toggle() {
            this.open = !this.open;
        },
        report() {
            this.open = false;
        },
        async deleteComment() {
            let modal = this.$root.$children[0].$refs.modal;
            modal.action = 'Als u bevestigt, wordt de reactie definitief verwijderd.';
            modal.toggle();
            modal.onConfirm(async () => {
                if (!this.deleting && !this.flagging || !this.banning) {
                    this.$emit('deleting', true);
                    this.open = false;

                    if (this.utility.session()) {
                        let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/event/${this.comment.event}/comment/${this.comment._id}/delete`, {
                            method: 'POST',
                            body: JSON.stringify({
                                accessToken: localStorage.getItem('accessToken'),
                            })
                        });

                        if (response.status == 200) {
                            this.$emit('removeComment', this.comment._id);
                        }
                        
                        this.$emit('deleting', false);
                    }
                }
            });
        },
        async flagComment() {
            let modal = this.$root.$children[0].$refs.modal;
            modal.action = 'Rapporteer commentaar als u denkt dat de inhoud ongepast is';
            modal.toggle();
            modal.onConfirm(async () => {
                if (!this.deleting && !this.flagging || !this.banning) {
                    this.$emit('flagging', true);
                    this.open = false;
                    let captchaToken = await grecaptcha.execute(process.env.VUE_APP_CAPTCHA_SITE_KEY, { action: 'flag' });

                    if (this.utility.session()) {
                        let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/event/${this.comment.event}/comment/${this.comment._id}/flag`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                                'x-captcha-token': captchaToken,
                            },
                            body: JSON.stringify({
                                reason: 'abusive user',
                            })
                        }).catch(console.log);

                        if (response.status == 200) {
                            let data = await response.json();
                            this.$emit('flagComment', data.message);
                        }

                        this.$emit('flagging', false);
                    }
                }
            });
        },
        async banUser() {
            let modal = this.$root.$children[0].$refs.modal;
            modal.action = 'Als u bevestigt, wordt de gebruiker permanent geblokkeerd.';
            modal.toggle();
            modal.onConfirm(async () => {
                if (!this.deleting && !this.flagging || !this.banning) {
                    this.$emit('banning', true);
                    this.open = false;
                    let captchaToken = await grecaptcha.execute(process.env.VUE_APP_CAPTCHA_SITE_KEY, { action: 'ban' });

                    if (this.utility.session()) {
                        let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/user/block`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                                'x-captcha-token': captchaToken,
                            },
                            body: JSON.stringify({ userBlocked: this.comment.user._id })
                        }).catch(console.log);

                        if (response.status == 200) {
                            this.$emit('blockUser', this.comment.user._id);
                            this.addBlockToSession();
                        }

                        this.$emit('banning', false);
                    }
                }
            });
        },
        addBlockToSession() {
            let blocks = JSON.parse(localStorage.getItem('blocks'));

            blocks.push({
                userBlocked: this.comment.user._id,
                userBlocking: this.user._id,
            });

            localStorage.setItem('blocks', JSON.stringify(blocks));
        },
        session() {
            return this.utility.session();
        },
        close() {
            this.open = false;
        },
    }
}
</script>

<style>
.options {
    position: absolute;
    bottom: 35px;
    right: 10px;
    text-align: right;
    font-size: 12px;
    background: #fff;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.options ul {
    margin: 0;
}

.options ul li {
    cursor: pointer;
    padding-left: 5px;
}

.options ul li:hover {
    background: #f5f5f5;
}

.options .btn-icon.btn-icon__small {
    font-size: 12px;
    background: none;
}

.options .fa-flag {
    color: red;
}
</style>
