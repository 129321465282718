<template>
    <div>
        <div v-if="!loading" class="rating-body">
            <div class="starbar">
                <div
                    v-for="i in 5"
                    :key="i"
                    class="star"
                    :class="{
                        'active': (editing && i <= userRating) || (!editing && i <= rating),
                        'editable': utility.session()
                    }"
                    @click="editRating(i)"
                />
            </div>
            <span class="rating">{{ rating }}/5</span>
        </div>

        <p v-if="!loading && !utility.session()">
            <a href="javascript:void(0)" @click="showLogin">InLoggen</a> om te stemmen
        </p>

        <p v-if="!loading && utility.session() && userRating" v-text="`Tus estrellas: ${userRating}/5`" />

        <p v-if="loading" class="rating_loading">
            Beoordeling laden...
            <i class="fas fa-spinner fa-spin" />
        </p>

        <div v-if="editing" class="rating-icons">
            <i class="fas fa-check-circle" @click="saveRating" />
            <i class="fas fa-times-circle" @click="cancelRating" />
        </div>
    </div>
</template>

<script>
import Utility from '../../utility';

export default {
    props: {
        eventId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            rating: null,
            userRating: null,
            editing: false,
            loading: true,
            utility: new Utility(),
        };
    },
    created() {
        this.loadRating();
    },
    methods: {
        editRating(rating) {
            if (this.utility.session()) {
                this.userRating = rating;
                this.editing = true;
            } else {
                this.$emit('showLogin');
            }
        },
        async saveRating() {
            this.editing = false;

            if (this.utility.session()) {
                this.loading = true;

                let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/event/${this.eventId}/rating`, {
                    method: 'POST',
                    body: JSON.stringify({
                        stars: this.userRating,
                        accessToken: localStorage.getItem('accessToken'),
                    }),
                });

                if (response.status == 200) {
                    let { data } = await response.json();
                    this.rating = data.average;
                }

                this.loading = false;
            }
        },
        cancelRating() {
            this.editing = false;
            this.userRating = null;
        },
        async loadRating() {
            let options = {
                method: 'GET',
            };

            if (this.utility.session()) {
                options.headers = {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                };
            }

            let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/event/${this.eventId}/rating`, options);

            if (response.status == 200) {
                let { data } = await response.json();
                this.rating = this.utility.limitDecimals(data.average, 2);

                if (data.userRating) {
                    this.userRating = data.userRating;
                }
            }

            this.loading = false;
        },
        showLogin() {
            this.$emit('showLogin');
        },
        reactToSession() {
            this.$forceUpdate();
        },
    },
};
</script>

<style>
.rating-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    gap: 0.5em;
}

.rating-icons i {
    font-size: 25px;
}

.rating-icons i:first-child {
    color: green;
}

.rating-icons i:last-child {
    color: #333;
}

.rating_loading {
    text-align: center;
    margin-top: 20px;
}

.starbar .star.editable {
    cursor: pointer;
}
</style>
