<template>
    <div v-if="showEventBackground" id="event">
        <div class="background" />

        <perfect-scrollbar class="card" :class="{ 'night': isNightime }">
            <div class="quit-cross">
                <button @click="quit">
                    <i class="fas fa-times" />
                </button>
            </div>

            <div class="header">
                <h1 v-text="event.name" />
            </div>

            <div class="body">
                <EventDate :date="event.eventdate" />

                <Map
                    v-if="showMap"
                    ref="location"
                    :event="event"
                    :is-nightime="isNightime"
                />

                <Rating
                    ref="rating"
                    :event-id="event._id"
                    @showLogin="$emit('showLogin')"
                />
            
                <div class="info">
                    <h3>Afstanden</h3>

                    <div v-for="distance in event.distances" :key="distance" class="badge">
                        {{ distance < 5 ? distance * 1000 + ' m' : distance + ' km' }}
                    </div>

                    <div v-if="event.description">
                        <h3>Beschrijving</h3>

                        <p v-text="event.description" />
                    </div>
                </div>

                <Gallery
                    v-if="event.images.length > 0"
                    :images="event.images"
                    @openModal="openModal"
                />

                <div v-if="event.website" class="website">
                    <span>🌐 Website: </span><a 
                        :href="event.website" 
                        target="_blank"
                    >{{ event.website }}</a>
                </div>

                <div v-if="event.regLink" class="registration mt-1">
                    <a 
                        :href="event.regLink" 
                        target="_blank" 
                        class="btn btn-primary"
                    >Inschrijven</a>
                </div>

                <Comments
                    ref="comments"
                    :event-id="event._id"
                    @showLogin="$emit('showLogin')"
                />
            </div>
        </perfect-scrollbar>

        <Modal ref="modal" :images="event.images" @quitEvent="quit" />
    </div>
</template>

<script>
import Map from './EventMap.vue';
import Gallery from './EventGallery.vue';
import Modal from './EventGalleryModal.vue';
import EventDate from '../shared/EventDate.vue';
import Rating from './EventRating.vue';
import Utility from '../../utility';
import Comments from './Comments/Comments.vue';

export default {
    components: {
        Map,
        Rating,
        Gallery,
        Modal,
        Comments,
        EventDate,
    },
    props: {
        event: {
            type: Object,
            default: () => ({}),
        },
        isNightime: {
            type: Boolean,
            default: false,
        },
        showEventBackground: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMobile: false,
            utility: new Utility(this),
            showMap: false,
        };
    },
    created() {
        this.isMobile = window.innerWidth < 768;
    },
    mounted() {
        this.loadMap();
        this.reactToSession();
    },
    beforeDestroy() {
        this.$refs.location.map.destroy();
    },
    methods: {
        loadMap() {
            if (!this.isMobile) {
                this.showMap = true;
            }
        },
        async quit() {
            this.$emit('quitEvent');
        },
        openModal(index) {
            this.$refs.modal.openModal(index);
        },
        reactToSession() {
            this.$refs.comments.reactToSession();
            this.$refs.rating.reactToSession();
        },
        onRatingChanged(rating) {
            this.$emit('rating-changed', rating);
        },
    },
}
</script>

<style>
.info ul {
    list-style: none;
    padding: 0;
    padding-left: 20px;
}

.info ul li {
    padding: 5px 0;
    position: relative;
}

.info ul li::before {
    content: '•';
    position: absolute;
    left: -20px;
    color: #007BFF;
    font-size: 20px;
}

.website {
    text-align: left;
    padding-left: 10%;
}
</style>