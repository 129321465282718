<template>
    <div v-if="open" class="modal">
        <div class="modal__background" />
        <div class="modal__content">
            <div class="modal__header">
                <h1 class="modal__title" v-text="'Confirmar acción?'" />
                <p>{{ action }}</p>
            </div>
            <div class="modal__body">
                <div class="modal__buttons">
                    <button class="btn btn-cancel" @click="close" v-text="'Cancelar'" />
                    <button class="btn" @click="confirm" v-text="'Confirmar'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            open: false,
            action: '',
            listeners: [],
        };
    },
    methods: {
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        confirm() {
            this.close();
            this.callback();
        },
        onConfirm(callback) {
            this.callback = callback;
        },
    },
}
</script>

<style scoped>
.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.modal__content {
    position: relative;
    width: 100%;
    max-width: 320px;
    height: auto;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
}

.modal__title {
    display: flex;
    align-items: center;
}

.modal__title h1 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.modal__close {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

.modal__close i {
    font-size: 16px;
    color: #333;
}

.modal__body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}
.modal__buttons button:first-child {
    margin-right: 10px;
}
</style>