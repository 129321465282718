<template>
    <div class="eventdate">
        <h3 v-if="name" :class="{ 'name_present': name }" v-text="name" />
        <p :class="{ 'name_present': name }">
            <b>{{ capitalize(formattedDate) }}</b>
        </p>

        <div class="countdown" :class="{ 'aan_de_gang': aanDeGang && name }">
            <span v-if="aanDeGang">Wedstrijd is aan de gang</span>
            <span v-if="months">{{ months }} maanden<span v-if="days || hours || minutes || seconds">, </span></span>
            <span v-if="days">{{ days }} dagen<span v-if="hours || minutes || seconds"> en </span></span>
            <span v-if="hours || minutes || seconds">
                <span>{{ hours.toString().padStart(2, '0') }}:</span>
                <span>{{ minutes.toString().padStart(2, '0') }}:</span>
                <span>{{ seconds.toString().padStart(2, '0') }}</span>
            </span>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const props = defineProps({
    date: {
        type: [String, Date],
        required: true
    },
    name: {
        type: String,
        default: null
    }
});

const months = ref(0);
const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const formattedDate = ref('');
let eventdate = ref(props.date);

const aanDeGang = computed(() => {
    return !months.value && !days.value && !hours.value && !minutes.value && !seconds.value;
});

onMounted(() => {
    init();
    updateCountdown();

    if (props.name) {
        const day = eventdate.value.getDate().toString().padStart(2, '0');
        const month = (eventdate.value.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
        const year = eventdate.value.getFullYear();
        formattedDate.value = `${day}/${month}/${year}`;
    } else {
        formattedDate.value = eventdate.value.toLocaleDateString('nl-NL', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    setInterval(updateCountdown, 1000);
});

const init = () => {
    if (typeof eventdate.value === 'string') {
        eventdate.value = new Date(eventdate.value);
    }
};

const updateCountdown = () => {
    const now = new Date();
    const diff = eventdate.value - now;

    if (diff < 0) {
        months.value = 0;
        days.value = 0;
        hours.value = 0;
        minutes.value = 0;
        seconds.value = 0;
        return;
    }

    months.value =  Math.floor(diff / 1000 / 60 / 60 / 24 / 30);
    days.value =    Math.floor(diff / 1000 / 60 / 60 / 24 % 30);
    hours.value =   Math.floor(diff / 1000 / 60 / 60 % 24);
    minutes.value = Math.floor(diff / 1000 / 60 % 60);
    seconds.value = Math.floor(diff / 1000 % 60);
};

const capitalize = (str) => str.replace(/\b\w/g, l => l.toUpperCase());
</script>

<style scoped lang="scss">
.eventdate {
    font-family: Arial, sans-serif;
    color: #333;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 20px;

    h3 {
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .countdown {
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
        padding: 10px;
        border-radius: 20px;
        background: #f5f5f5;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        width: calc(100% - 20px);

        &.aan_de_gang {
            background: #46b566;
            color: white;
        }
    }

    h3.name_present {
        display: inline-block;
        text-align: left;
    }

    p.name_present {
        display: inline-block;
        text-align: right;
    }
}

@media screen and (min-width: 769px) {
    .eventdate h3.name_present {
        width: 60%;
    }
    p.name_present {
        width: 40%;
    }
}


@media screen and (max-width: 768px) {
    .eventdate {
        h3 {
            font-size: 1.2em;
            text-align: center;

            &.name_present {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow-x: clip;
                width: 70%;
                font-size: 0.85rem;
            }
        }
        p {
            font-size: 0.8em;

            &.name_present {
                width: 30%;
            }
        }
        .countdown {
            font-size: 1em;
            span,
            span span {
                font-size: 1em;
            }
        }
    }
}
</style>