<template>
    <div id="profile">
        <h1>Perfil</h1>

        <table>
            <tr>
                <td><b>Gebruikersnaam</b></td>
                <td>{{ user.username }}</td>
            </tr>
            <tr>
                <td><b>E-mail</b></td>
                <td>{{ user.email }}</td>
            </tr>
            <tr>
                <td><b>Van</b></td>
                <td>{{ utility.formatDate(user.createdDate) }}</td>
            </tr>
        </table>
        
        <button class="btn" @click="logout" v-text="'Afmelden'" /><br>

        <a
            href="javascript:void(0)" 
            class="btn__delete-account" 
            @click="deleteAccount" 
            v-text="'Account verwijderen'"
        />
    </div>
</template>

<script>
import Utility from '../../utility';

export default {
    data() {
        return {
            user: {
                username: '',
                email: '',
            },
            utility: new Utility(),
        };
    },
    mounted() {
        if (this.utility.session()) {
            this.user = JSON.parse(localStorage.getItem('user'));
        } else {
            this.$emit('showLogin');
        }        
    },
    methods: {
        logout() {
            this.utility.logout();
            this.$emit('showLogin');
        },
        deleteAccount() {
            this.$emit('deleteAccount');
        },
    },
};
</script>

<style>
#profile table {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: left;
}

#profile .btn__delete-account {
    margin-top: 20px;
    display: block;
}
</style>