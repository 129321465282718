<template>
    <div class="references">
        <div class="references__open_arrow" @click="toggle">
            <i v-if="open" class="fas fa-angle-down" />
            <i v-if="!open" class="fas fa-angle-up" />
        </div>

        <div class="references__container" @click="toggle">
            <h2 v-if="!open" class="title" v-text="'Referenties'" />

            <ul v-if="open" class="m-top__small">
                <h2 class="title" v-text="'Referenties'" />

                <li class="m-top__small">
                    <img src="/assets/marker_event.png">
                    <span v-text="'Wedstrijd'" />
                </li>
                <li>
                    <img src="/assets/marker_location_2.png">
                    <span v-text="'Jouw locatie'" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            open: false,
        }
    },
    methods: {
        toggle() {
            this.open = !this.open;
        }
    }
}
</script>
