/**
 * CameraDesktop manipulates the camera of the map to move to a specific location in desktop (feature not available for Capacitor).
 * 
 * @param {Object} destiny - The destiny coordinates.
 * @param {Object} context - The context object.
 * 
 */

export default class CameraDesktop {
    constructor(context) {
        this.context = context;
    }

    setCamera(destiny) {
        this.context.movingToClosestInMotion = true;

        return new Promise((resolve, reject) => {
            let startLat = this.context.screen.coordinates.lat;
            let startLng = this.context.screen.coordinates.lng;
            let endLat = destiny.lat;
            let endLng = destiny.lng;
            let steps = 100;
            let delay = 5;

            for (let i = 0; i <= steps; i++) {
                setTimeout(() => {
                    this.context.map.setCamera({
                        coordinate: {
                            lat: startLat + (endLat - startLat) * (i / steps),
                            lng: startLng + (endLng - startLng) * (i / steps),
                        },
                        animate: false,
                    });

                    if (i === steps) {
                        this.context.movingToClosestInMotion = false;
                        resolve();
                    }
                }, delay * i);
            }
        });
    }
};