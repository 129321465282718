<template>
    <div id="forgot">
        <h1>Wachtwoord herstellen</h1>
        
        <Form
            ref="form"
            action="forgot"
            action-label="Herstellen"
            :fields="fields"
            :add-captcha="true"
            @submit="recover"
        />

        <p>
            <a href="javascript:void(0)" @click="showLogin">Terug naar inloggen</a>
        </p>
    </div>
</template>

<script>
import Form from '../shared/Form.vue';

export default {
    components: {
        Form,
    },
    data() {
        return {
            fields: [
                {
                    id: 'username',
                    label: 'Gebruiker',
                    value: '',
                    type: 'text',
                    required: true,
                },
            ],
            loading: false,
        };
    },
    watch: {
        loading(loading) {
            this.$emit('setLoadingState', loading);
            this.$refs.form.submitting = loading;
        },
    },
    mounted() {
        document.querySelector('#username').focus();
    },
    methods: {
        async recover(body) {
            this.loading = true;
            
            this.$refs.form.globalError = '';
            this.$refs.form.globalMessage = '';

            let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/user/recover`, {
                method: 'POST',
                body: JSON.stringify(body),
            });
            
            let data = await response.json();

            this.loading = false;

            this.$refs.form.cleanFormAndInputs();
            
            if (response.status === 200) {
                this.$nextTick(() => {
                    this.$emit('showRecoverConfirm');
                });
            } else {
                this.$refs.form.globalError = data.error;
            }
        },
        showLogin() {
            this.$emit('showLogin');
        },
    },
};
</script>
