<template>
    <div id="recoverconfirm">
        <h1>Wachtwoord herstellen</h1>
        
        <Form
            v-if="!businessDone"
            ref="form"
            action="recoverconfirm"
            action-label="Herstellen"
            :fields="fields"
            :add-captcha="true"
            @submit="recoverconfirm"
        />

        <p v-if="error">
            <span class="error" v-text="error" />
        </p>

        <p v-if="message">
            <span v-text="message" />
        </p>

        <p>
            <a href="javascript:void(0)" @click="showLogin">Terug naar inloggen</a>
        </p>
    </div>
</template>

<script>
import Form from '../shared/Form.vue';
import Utility from '../../utility';

export default {
    components: {
        Form,
    },
    data() {
        return {
            fields: [
                {
                    id: 'password',
                    label: 'Nieuw paswoord',
                    value: '',
                    type: 'password',
                    required: true,
                },
                {
                    id: 'password_repeat',
                    label: 'Herhaal nieuw wachtwoord',
                    value: '',
                    type: 'password',
                    repeat: 'password',
                    required: true,
                },
                {
                    id: 'recoverPasswordCode',
                    label: 'Code',
                    value: '',
                    type: 'text',
                    required: true,
                    customValidation: {
                        minLength: 8,
                        maxLength: 8,
                    },
                },
            ],
            loading: false,
            utility: new Utility(),
            businessDone: false,
            error: null,
            message: null,
        };
    },
    watch: {
        loading(loading) {
            this.$emit('setLoadingState', loading);
            this.$refs.form.submitting = loading;
        },
    },
    mounted() {
        document.querySelector('#password').focus();
    },
    methods: {
        async recoverconfirm(body) {
            this.loading = true;
            
            this.error = '';
            this.$refs.form.globalError = '';
            this.$refs.form.globalMessage = '';

            let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/user/recoverconfirm`, {
                method: 'POST',
                body: JSON.stringify(body),
            });
            
            let data = await response.json();

            this.loading = false;
            
            if (response.status === 200) {
                this.message = data.message;
                this.businessDone = true;
            } else {
                this.error = data.error;
                this.$refs.form.cleanPassword();
            }
        },
        showLogin() {
            this.$emit('showLogin');
        },
    },
};
</script>
