/**
 * This is the very start of the application.
 * It is responsible for initializing the application.
 * 
 * @author Federico Sosa
 * @version 1.2
 */

import Vue from 'vue';
import App from './App.vue';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.use(PerfectScrollbar);
Vue.use(VueLodash, { name: 'custom' , lodash });

Vue.config.productionTip = false;

new Vue({
    render: h => h(App),
}).$mount('#app');
