<template>
    <div>
        <div class="gallery">
            <div v-for="(image, index) in images" :key="index" class="image">
                <img :src="getImage(image)" @click="openModal(index)">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        openModal(index) {
            this.$emit('openModal', index);
        },
        getImage(image) {
            return `https://images.racetrijd.nl/events/${image.event}/${image.filename}`;
        },
    },
};
</script>