<template>
    <div id="init-delete">
        <h1>Accountverwijdering</h1>

        <p v-if="!codeSent">
            Om te voorkomen dat u per ongeluk uw account verwijdert, sturen wij u een code 
            naar het e-mailadres dat u heeft geregistreerd, die u moet kopiëren en plakken 
            in het veld dat verschijnt zodra u het proces start.
        </p>

        <Form
            v-if="!codeSent"
            ref="form"
            action="initdelete"
            action-label="Proces starten"
            :add-captcha="true"
            @submit="initDelete"
        />

        <Form
            v-if="codeSent && !businessDone"
            ref="form"
            action="confirmdelete"
            action-label="Bevestigen"
            :fields="fields"
            :add-captcha="true"
            @submit="confirmDelete"
        />

        <p v-if="error">
            <span class="error" v-text="error" />
        </p>

        <p v-if="message">
            <span v-text="message" />
        </p>

        <p v-if="!businessDone">
            <a href="javascript:void(0)" @click="showProfile">Terug naar profiel</a>
        </p>

        <p v-if="businessDone">
            <a href="javascript:void(0)" @click="showLogin">Terug naar inloggen</a>
        </p>
    </div>
</template>

<script>
import Form from '../shared/Form.vue';
import Utility from '../../utility';

export default {
    components: {
        Form,
    },
    data() {
        return {
            fields: [
                {
                    id: 'deleteCode',
                    label: 'Code',
                    value: '',
                    type: 'text',
                    required: true,
                    customValidation: {
                        minLength: 8,
                        maxLength: 8,
                    },
                },
            ],
            codeSent: false,
            loading: false,
            utility: new Utility(),
            businessDone: false,
            error: null,
            message: null,
        };
    },
    watch: {
        loading(loading) {
            this.$emit('setLoadingState', loading);
            this.$refs.form.submitting = loading;
        },
    },
    methods: {
        async initDelete(body) {
            if (this.utility.session()) {
                this.loading = true;
                
                this.error = '';
                this.$refs.form.globalError = '';
                this.$refs.form.globalMessage = '';

                let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/user/init-delete`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        'x-captcha-token': body.captchaToken,
                    },
                });

                let data = await response.json();
                this.loading = false;
                
                if (response.status === 200) {
                    this.codeSent = true;
                } else {
                    this.error = data.error;
                }
            } else {
                this.$emit('showLogin');
            }
        },
        async confirmDelete(body) {
            if (this.utility.session()) {
                this.loading = true;
                
                this.error = '';
                this.$refs.form.globalError = '';
                this.$refs.form.globalMessage = '';

                let response = await fetch(`${process.env.VUE_APP_API_GATEWAY_URL}/v1/user/confirm-delete`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        'x-captcha-token': body.captchaToken,
                    },
                    body: JSON.stringify(body),
                });

                let data = await response.json();
                this.loading = false;
                
                if (response.status === 200) {
                    this.message = data.message;
                    this.businessDone = true;
                    this.utility.logout();
                    this.$emit('reactToSession');
                } else {
                    this.error = data.error;
                }
            } else {
                this.$emit('showLogin');
            }
        },
        showProfile() {
            this.$emit('showProfile');
        },
        showLogin() {
            this.$emit('showLogin');
        },
    },
};
</script>
